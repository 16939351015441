import {Controller} from "@hotwired/stimulus";
import Preloader from '../../../components/preloader';

export default class extends Controller {
    connect() {
        document.querySelector('[data-ajax]').addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            const target = e.target;

            if (target.hasAttribute('disabled')) {
                return;
            }

            if (target.classList.contains('disabled')) {
                return; // Если есть, выходим из функции
            }

            if (target.hasAttribute('data-after-click')) {
                target.classList.add(target.getAttribute('data-after-click'));
            }

            const xhttp = new XMLHttpRequest();
            xhttp.open('POST', target.getAttribute('href'), true);
            xhttp.send();
            Preloader.remove();
        });
    }
}